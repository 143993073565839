import httpsClient from "./httpsClient";
import { APP_URLs, config } from "../constants/appURLs";
import { IThirdPartyAPI } from "../constants/types";
import { snackActions } from "../helpers/SnackUtilsConfigurator";
import { isAdmin } from "../hooks/useUserInfo";

//Dashboard
const getDashboard = async (query: any) => {
  try {
    const params: any = {};

    if (query?.startDate) {
      params.startDate = query?.startDate;
    }
    if (query?.endDate) {
      params.endDate = query?.endDate;
    }
    if (query?.startTime) {
      params.startTime = query?.startTime;
    }
    if (query?.endTime) {
      params.endTime = query?.endTime;
    }
    if (query?.itemId) {
      params.itemId = query?.itemId;
    }
    if (query?.categoryId) {
      params.categoryId = query?.categoryId;
    }
    if (query?.person) {
      params.person = query?.person;
    }

    if (query?.responseTime) {
      params.responseTime = query?.responseTime;
    }

    const queryString = new URLSearchParams(params).toString();
    const { data } = await httpsClient.get(
      `${APP_URLs.dashboard.getDashboard}?${queryString}`,

      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(
      error.response.data.message ??
      "Something went wrong!, please try again",
    );
  }
};

const refreshToken = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      `${APP_URLs.refreshToken.refresh}`,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

// Golf Query
const getAllGolfClub = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${isAdmin ? APP_URLs.golfEndPoints.getAllGolf : APP_URLs.golfEndPoints.golfCommonAPI}?offset=${query?.offset ?? ""
      }&limit=${query?.limit ?? ""}&type=${query?.type ?? ""}&golfType=${query?.golfType ?? ""
      }&search=${query?.search ?? ""}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getGolfClub = async (golfId: string) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.golfEndPoints.golfCommonAPI}/${golfId}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addGolfClub = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.golfEndPoints.golfCommonAPI,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const deleteGolfClub = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.delete(
      `${APP_URLs.golfEndPoints.golfCommonAPI}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const deleteRestrictedSlot = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.delete(
      APP_URLs.squezzMe.deleteSlot,
      {
      data: payloadData,
      ...config
  });
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const activeDeActiveGolfClub = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.golfEndPoints.activeDeactiveGolf,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addEditGolfHoles = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.golfEndPoints.addEditGolfHoles,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

// Common
const getCountries = async () => {
  try {
    const { data } = await httpsClient.get(
      APP_URLs.commonEndPoints.getCountries,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getStates = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.commonEndPoints.getStates,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getCities = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.commonEndPoints.getCities,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getTerms = async () => {
  try {
    const { data } = await httpsClient.get(
      APP_URLs.termsAndCondition.getTerms,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getPrivacy = async () => {
  try {
    const { data } = await httpsClient.get(
      APP_URLs.privacyAndPolicy.getPrivacy,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getPaymentTerms = async () => {
  try {
    const { data } = await httpsClient.get(
      APP_URLs.paymentTerms.getPaymentTerms,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getPaymentPrivacy = async () => {
  try {
    const { data } = await httpsClient.get(
      APP_URLs.paymentPrivacy.getPaymentPrivacy,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const getAboutUs = async () => {
  try {
    const { data } = await httpsClient.get(APP_URLs.aboutUs.getAboutUs, config);
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};



const getAllSubscription = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.subscription.SubscriptionCommonAPI}?offset=${query?.offset}&limit=${query?.limit}&search=${query?.search}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addSubscription = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.subscription.SubscriptionCommonAPI,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const deleteSubscription = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.delete(
      `${APP_URLs.subscription.SubscriptionCommonAPI}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addPaymentPrivacy = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.put(
      APP_URLs.paymentPrivacy.addPaymentPrivacy,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addPaymentTerms = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.put(
      APP_URLs.paymentTerms.addPaymentTerms,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addAboutUs = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.put(
      APP_URLs.aboutUs.addAboutUs,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addTerms = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.put(
      APP_URLs.termsAndCondition.addTerms,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addPrivacy = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.put(
      APP_URLs.privacyAndPolicy.addPrivacy,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

// faq
const getAllFAQs = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.faq.genericFAQAPI}?offset=${query?.offset}&limit=${query?.limit}&search=${query?.search}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addEditGolfFAQ = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.faq.genericFAQAPI,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getAppCustomerList = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.appUser.appCustomerList}?roleId=${query?.roleId}&offset=${query?.offset}&limit=${query?.limit}&search=${query?.search}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getOffers = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.offers.offersCommon}?golfId=${query?.golfId}&offset=${query?.offset}&limit=${query?.limit}&search=${query?.search}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addEditOffer = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.offers.offersCommon,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const deleteOffer = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.delete(
      `${APP_URLs.offers.offersCommon}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const deleteBanner = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.delete(
      `${APP_URLs.banners.bannerComnonApi}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const deleteFaq = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.delete(
      `${APP_URLs.faq.genericFAQAPI}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getAllCompany = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.company.genericCompanyAPI}?offset=${query?.offset ?? ""
      }&limit=${query?.limit ?? ""}&search=${query?.search ?? ""}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addEditCompany = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.company.genericCompanyAPI,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const deleteCompany = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.delete(
      `${APP_URLs.company.genericCompanyAPI}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addAdmin = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.appUser.addAdmin,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const editAdmin = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.put(
      APP_URLs.appUser.editAdmin,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addEditThirdPartyAPI = async (payloadData: IThirdPartyAPI) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.golfEndPoints.addEditThirdPartyAPI,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const suspendActiveAdmin = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.put(
      APP_URLs.appUser.suspendAdmin,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const uploadPicture = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.put(APP_URLs.mediaUpload, payloadData, config);
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getSlotBooking = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.booking.genericSlotBookingAPI}?offset=${query?.offset ?? ""
      }&limit=${query?.limit ?? ""}&search=${query?.search ?? ""}&categoryId=${query?.categoryId ?? ""}&itemId=${query?.itemId ?? ""}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

// const addEditBookingSlot = async (payloadData: IBookingSlot) => {
//   try {
//     const { data } = await httpsClient.post(
//       APP_URLs.booking.genericSlotBookingAPI,
//       payloadData,
//       config,
//     );
//     return data;
//   } catch (error: any) {
//     snackActions.dismissibleError(error?.message ?? "Something went wrong");
//   }
// };

const getSubscriptionInfo = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.subscriptionInfo.genericSubscriptionInfoAPI}?offset=${query?.offset ?? ""
      }&limit=${query?.limit ?? ""}&search=${query?.search ?? ""}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getAllCoupons = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.coupon.genericCouponAPI}?offset=${query?.offset ?? ""
      }&limit=${query?.limit ?? ""}&search=${query?.search ?? ""}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addEditCoupon = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.coupon.genericCouponAPI,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const deleteCoupon = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.delete(
      `${APP_URLs.coupon.genericCouponAPI}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getContactUsList = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.contactus.getContactusList}?offset=${query?.offset}&limit=${query?.limit}&search=${query?.search}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const getInquiryList = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.inquiry.getInquiryList}?offset=${query?.offset}&limit=${query?.limit}&search=${query?.search}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getMembershipDescription = async () => {
  try {
    const { data } = await httpsClient.get(
      APP_URLs.membershipDescription.genericMembershipDescription,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(
      error?.message ?? "Something went wrong, try again",
    );
  }
};

const addEditMembershipDescription = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.put(
      APP_URLs.membershipDescription.genericMembershipDescription,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getSquezzMeList = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.squezzMe.getSquezzMeList}?offset=${query?.offset ?? ""
      }&limit=${query?.limit ?? ""}&search=${query?.search ?? ""}&categoryId=${query?.categoryId ?? ""}&itemId=${query?.itemId ?? ""}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};


const updateRequestStatusForAll = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.squezzMe.updateRequestStatusForAll,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getInterestList = async (query: any) => {
 const selectedCategory = localStorage.getItem("selectedCategory");
 if (selectedCategory === "65c61866ea562b9cfd579468") {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.interest.genericInterestAPIforGolf}?type=${query?.type ?? ""}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
 }else{
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.interest.genericInterestAPI}?type=${query?.type ?? ""}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
 }

};

const addEditInterest = async (payloadData: any) => {
  const selectedCategory = localStorage.getItem("selectedCategory");
  if (selectedCategory === "65c61866ea562b9cfd579468") {
    try {
      const { data } = await httpsClient.post(
        APP_URLs.interest.genericInterestAPIforGolf,
        payloadData,
        config,
      );
      return data;
    } catch (error: any) {
      snackActions.dismissibleError(error?.message ?? "Something went wrong");
    }
  } else {
     try {
    const { data } = await httpsClient.post(
      APP_URLs.interest.genericInterestAPI,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
  }
};

const deleteInterest = async (payloadData: any) => {
  const selectedCategory = localStorage.getItem("selectedCategory");
  if (selectedCategory === "65c61866ea562b9cfd579468") {
    try {
      const { data } = await httpsClient.delete(
        `${APP_URLs.interest.genericInterestAPIforGolf}/${payloadData?.id}`,
        config,
      );
      return data;
    } catch (error: any) {
      snackActions.dismissibleError(error?.message ?? "Something went wrong");
    } 
  } else {
    try {
      const { data } = await httpsClient.delete(
        `${APP_URLs.interest.genericInterestAPI}/${payloadData?.id}`,
        config,
      );
      return data;
    } catch (error: any) {
      snackActions.dismissibleError(error?.message ?? "Something went wrong");
    }
  }
};
const deleteInterestForGolf = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.delete(
      `${APP_URLs.interest.genericInterestAPIforGolf}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const deleteApparel = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.delete(
      `${APP_URLs.apparel.genericApparelAPI}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getApparelList = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.apparel.genericApparelAPI}?type=${query?.type ?? ""}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const getApparelListForGolf = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.interest.genericInterestAPIforGolf}?type=${query?.type ?? ""}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addEditApparel = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.apparel.genericApparelAPI,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};


//Category

const addCategory = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.category.genericAddCategoryAPI,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
}


const getCategories = async () => {
  try {
    const { data } = await httpsClient.get(
      APP_URLs.category.genericCategoryAPI
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getAllItemsByCatId = async (payload: any) => {
  try {
    const { data } = await httpsClient.get(`${APP_URLs.category.getAllItemDetailsAPI}?cateId=${payload}`, config);
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
}

const deleteCategory = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.delete(
      `${APP_URLs.category.genericDeleteCategoryAPI}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getCategoryRestaurant = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${isAdmin ? APP_URLs.category.genericCategoryRestaurantforAdmin : APP_URLs.category.genericCategoryRestaurant}?offset=${query?.offset ?? ""
      }&limit=${query?.limit ?? ""}&search=${query?.search}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getRestaurantInfo = async (restaurantId: string) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.restaurant.getRestaurantDetails}/${restaurantId}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const deleteCategoryRestaurant = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.delete(
      `${APP_URLs.category.RestaurantCommonAPI}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addRestaurant = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.category.RestaurantCommonAPI,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const activeInActiveRestaurant = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.category.activeInActiveRestaurant,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    console.log(error)
  }
};

//banner
const getAllBanners = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.banners.bannerComnonApi}?offset=${query?.offset}&limit=${query?.limit}&search=${query?.search}`,
      config,
    );
    return data;
  } catch (error: any) {
    // snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addBanner = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.banners.bannerComnonApi,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    // snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getWaitingList = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.notify.generiWaitingListAPI}?offset=${query?.offset ?? ""
      }&limit=${query?.limit ?? ""}&search=${query?.search ?? ""}&categoryId=${query?.categoryId ?? ""}&itemId=${query?.itemId ?? ""}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const notify = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.notify.genericWaitingUserNotifyAPI,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const changePassword = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.put(
      APP_URLs.changeUserPassword.genericAPI,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
    // snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

//Wellness

const getAllWellnessList = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${isAdmin ? APP_URLs.wellness.genericWellnessAPIForAdmin : APP_URLs.wellness.genericWellnessAPI}?offset=${query?.offset ?? ""
      }&limit=${query?.limit || 1000}&search=${query?.search ?? ""}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addEditWellness = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.wellness.genericWellnessAPI,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const deleteWellness = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.delete(
      `${APP_URLs.wellness.genericWellnessAPI}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

//Healthcare

const getAllHealthCareList = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.health.genericHealthAPI}?offset=${query?.offset ?? ""
      }&limit=${query?.limit ?? ""}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

//hotel

const getAllHotelList = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${isAdmin ? APP_URLs.hotel.genericHotelforAdmin : APP_URLs.hotel.genericHotelAPI}?offset=${query?.offset ?? ""
      }&limit=${query?.limit || 1000}&search=${query?.search ?? ""}`,
      config,
    );
    return data;
  } catch (error: any) {
    console.error('Error:', error.response ? error.response.data : error.message);
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const deleteCategoryHotel = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.delete(
      `${APP_URLs.hotel.genericHotelAPI}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addHotel = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.hotel.genericHotelAPI,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addEditWidget = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.restaurant.widgetDetails,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const activeInActiveHotel = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.hotel.activeInActiveHotel,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    console.log(error)
  }
};

const restrictSlotRequest = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.squezzMe.slotRestrictRequest,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    console.log(error)
  }
};


const getPaymentProvider = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.payment_provider.provider}?categoryId=${payloadData?.catId}`,
      config,
    );
    return data.data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};


const getSlotDetailsById = async ( query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.squezzMe.getSlotRestrictDetails}?id=${query?.restaurantId}&offset=${query?.offset ?? ""
      }&limit=${query?.limit ?? ""}`,
      config,
    );
    return data.data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getWidgetDetails = async ( query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.restaurant.getWidgetDetails}?_id=${query?.restaurantId}&offset=${query?.offset ?? ""
      }&limit=${query?.limit ?? ""}`,
      config,
    );
    return data.data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const deleteWidgetDetails = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.delete(
      APP_URLs.restaurant.deleteWidget,
      {
      data: payloadData,
      ...config
  });
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};



export {
  getPaymentProvider,
  activeDeActiveGolfClub,
  addAboutUs,
  getSlotDetailsById,
  deleteWidgetDetails,
  addAdmin,
  getDashboard,
  deleteWellness,
  addBanner, addEditApparel,
  // addEditBookingSlot,
  addEditCompany,
  getAllWellnessList,
  getAllHealthCareList,
  addEditWellness,
  addEditCoupon,
  addEditGolfFAQ,
  addEditGolfHoles, addEditInterest, addEditMembershipDescription,
  addEditOffer,
  addEditThirdPartyAPI,
  addGolfClub,
  addPaymentPrivacy,
  addPaymentTerms,
  addPrivacy,
  addSubscription,
  addTerms, deleteApparel, deleteBanner,
  deleteCompany,
  deleteCoupon,
  deleteFaq,
  deleteGolfClub, deleteInterest, deleteOffer,
  deleteSubscription,
  editAdmin,
  getAboutUs,
  getAllBanners,
  getAllCompany,
  getAllCoupons,
  getAllItemsByCatId,
  getAllFAQs,
  getAllGolfClub,
  getAllSubscription,
  getAppCustomerList, getApparelList, getCities,
  getContactUsList,
  getInquiryList,
  getCountries,
  getGolfClub, getInterestList, getMembershipDescription, getOffers,
  getPaymentPrivacy,
  getPaymentTerms,
  getPrivacy,
  getSlotBooking, getSquezzMeList, getStates,
  getSubscriptionInfo,
  getTerms, suspendActiveAdmin, updateRequestStatusForAll, uploadPicture,
  getCategories,
  getCategoryRestaurant,
  deleteCategoryRestaurant,
  activeInActiveRestaurant,
  addRestaurant,
  getWaitingList,
  notify,
  addCategory,
  deleteCategory,
  changePassword,
  deleteInterestForGolf,
  getApparelListForGolf,
  getAllHotelList,
  deleteCategoryHotel,
  addHotel,
  activeInActiveHotel,
  getRestaurantInfo,
  restrictSlotRequest,
  deleteRestrictedSlot,
  addEditWidget,
  getWidgetDetails,
  refreshToken
};

